<!-- noData -->
<template>
  <div class="noData" style="margin-top: 18%">
    <noData></noData>
  </div>
</template>

<script>
import noData from '@comp/noData'

export default {
  name: 'NoData',
  data() {
    return {}
  },
  props: {},
  components: { noData },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
